@use 'sass:map';
@use '@angular/material' as mat;
@use 'app/components/payment-method-select/payment-method-select-theme.component' as paymentMethodSelect;
@use 'app/pages/products/giftcard/step1/step1.component-theme' as giftcardStep1;
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$bootjesenBroodjes-accent-palette: (
  50: #fff8e2,
  100: #ffebb7,
  200: #ffdf89,
  300: #ffd35b,
  400: #ffc83e,
  500: #ffbf30,
  600: #ffb12d,
  700: #ff9f2b,
  800: #ff8f2b,
  900: #ff7029,
  A100: #ffe2b3,
  A200: #ffbb4d,
  A400: #ff9000,
  A700: #f55000,
  A900: #e14700,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    A900: $light-primary-text,
  )
);

$bootjesenBroodjes-primary-palette: (
  50: #e0f6fa,
  100: #b2e9f3,
  200: #81dbeb,
  300: #31c1d9,
  400: #31c1d9,
  500: #20b6d2,
  600: #1da7bf,
  700: #1892a5,
  800: #157e8d,
  900: #0f5b62,
  A100: #c2cbec,
  A200: #99aadf,
  A400: #4b6fc8,
  A700: #0044a9,
  A900: #002889,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    A900: $light-primary-text,
  )
);

$my-primary: mat.define-palette($bootjesenBroodjes-primary-palette, 700);
$my-accent: mat.define-palette($bootjesenBroodjes-accent-palette, A700, A400, A900);
$my-success: mat.define-palette(mat.$light-green-palette, 700, 500, 900);

$primary-default: mat.get-color-from-palette($my-primary, default);
$accent-default: mat.get-color-from-palette($my-accent, default);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  density: 0,
));

$color-config:    mat.get-color-config($my-theme);
$warn-palette:  map.get($color-config, 'warn');

h1, h2, h3, title {
  color: mat.get-color-from-palette($my-primary, default);
}

.primary-color {
  color: mat.get-color-from-palette($my-primary, default);
}

.accent-color {
  color: mat.get-color-from-palette($my-accent, default);
}

mat-form-field {
  width: 100%;
  max-width: 400px;
  display: block !important;
}

mat-icon[matsuffix] {
  color: rgba(0, 0, 0, .54);
}

// For showing availability in calendar
button.calendar-date-primary {
  border-radius: 50%;
  /*
  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: mat.get-color-from-palette($my-primary, default);
    color: mat.get-color-from-palette($my-primary, default-contrast);
  }*/
}
/*
.mat-calendar-body-cell-content {
  &.mat-calendar-body-selected {
    background-color: mat.get-color-from-palette($my-accent, default);
    color: mat.get-color-from-palette($my-accent, default-contrast);
  }
}*/


button.accent-icon-button {
  background-color: mat.get-color-from-palette($my-accent, default);
  color: mat.get-color-from-palette($my-accent, default-contrast);

  &:hover {
    background-color: mat.get-color-from-palette($my-accent, darker);
  }
}

.two-tone-primary {
  color: transparent;
  background: mat.get-color-from-palette($my-primary, default);
  background-clip: text;
  -webkit-background-clip: text;
}

.two-tone-accent {
  color: transparent;
  background: mat.get-color-from-palette($my-accent, default);
  background-clip: text;
  -webkit-background-clip: text;
}

.two-tone-warn {
  color: transparent;
  background: mat.get-color-from-palette($warn-palette, default);
  background-clip: text;
  -webkit-background-clip: text;
}

.two-tone-success {
  color: transparent;
  background: mat.get-color-from-palette($my-success, default);
  background-clip: text;
  -webkit-background-clip: text;
}

.primary-border {
  border-color: mat.get-color-from-palette($my-primary, default) !important;
}

@include mat.all-component-themes($my-theme);
@include paymentMethodSelect.color($my-theme);
@include giftcardStep1.color($my-theme);

:root {
  --swiper-theme-color: #{mat.get-color-from-palette($my-accent, darker)};
}
