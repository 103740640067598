@use "theme" as theme;

@import "node_modules/swiper/swiper";
@import 'node_modules/swiper/modules/navigation';
@import 'node_modules/swiper/modules/pagination';
@import "@fontsource/material-icons";
@import "@fontsource/material-icons-two-tone";
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

.material-icons {
  font-family: 'Material Icons', serif;
  font-size: 24px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f3fafb;
}

mat-stepper {
  background-color: transparent !important;
}

.read-more{
  max-height:240px;
  overflow: hidden;
  position: relative;

  &:after{
    content:'';
    width:100%;
    position: absolute;
    height:50px;
    bottom:0;
    left:0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

  &.open{
    max-height: none;
    &::after{
      display:none;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eee;
}

button[matsteppernext] {
  width: 100%;
}

.no-shadow{
  box-shadow: none !important;
  border:1px solid #ddd !important;
}

mat-card:not(:last-child), .card-like:not(:last-child) {
  margin-bottom: 1rem;
}

mat-card {
  border:1px solid #ddd;
  box-shadow: none;
  &.selected{
    border-color:rgba(245, 80, 0, 1)  !important;;
    background:rgba(245, 80, 0, 0.12);
  }
}

.dark-backdrop {
  background-color: rgba(0, 0, 0, .7);
}

.text-icon {
  margin-bottom: -.25rem;
}

mat-form-field {
  &.small-footprint {
    margin-bottom: -1.25em !important;
  }

  &.full-width {
    width: 100%;
  }
}

.full-width-summary mat-card{
  width:100% !important;
}

@media only screen and (max-width: 1024px) {
  .mat-horizontal-stepper-wrapper {
    flex-grow: 1;
    justify-content: space-between;
  }
}

.selected {
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border:1px solid theme.$accent-default;
  background:rgba(theme.$accent-default,.12);
}

.mat-mdc-icon-button.language-button{
  background-color:rgba(255,255,255,0.2);
  margin-left:4px;
  padding:4px;
  width:36px;
  height:36px;
  border:2px solid transparent;
  opacity: .6;
  &.selected {
    opacity: 1;
    border:2px solid #fff !important;
  }
  &:hover:not(.selected){
    opacity: 1;
    border:2px solid rgba(255,255,255,.3) !important;
  }

  mat-icon{
    width:100%;
    height:100%;
    border-radius: 50%;
    overflow: hidden;
    svg{
      width: 31px;
      height:100% !important;
      margin-left:-3px;
    }
  }
}

.mat-horizontal-stepper-header-container{
  padding-left:24px;
  padding-right: 24px;
  .mat-step-icon{
    width:36px;
    height:36px;
    font-weight: bold;
    color:theme.$primary-default;
    border:2px solid #1892a5;
    background:#D9F3F7;
    &.mat-step-icon-state-edit{
      border:2px solid theme.$primary-default;
      background: #fff;
      color: theme.$primary-default;
    }
    &.mat-step-icon-state-done{
      background: theme.$primary-default;
      color: #fff;
    }
  }
}

.alert{
  position: relative;
  border-top:2px dashed rgba(0, 0, 0, 0.12);
  padding-top:1rem;
  padding-bottom: 1rem;
  color:rgba(theme.$dark-primary-text,.7);
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.alert-info{
    color: #216B77;
    background-color: #F3FAFB;
   border-color: #DEF2F5;
  }

  &.alert-danger{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.totals{
  font-size: 18px;
  font-weight: 500;
  padding-top:16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after{
  width:calc(50% - 40px) !important;
}

button.calendar-date-primary .mat-calendar-body-cell-content:not(.mat-calendar-body-selected){
  color:darken(#1892a5,10%);
  background:rgba(#1892a5,.2)
}

button.calendar-date-primary.mat-calendar-body-selected{
  color:#fff;
  background:darken(#1892a5,20%);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border:2px solid #1892a5;
  background:rgba(#1892a5,.2) !important;
}

button.calendar-date-primary.mat-calendar-body-today{
  border:2px solid #1892a5 !important;
}

.form-row{
  width:100%;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom:24px;
  .form-label-heading{
    padding-right:32px;
    h2{
     font-size: 18px;
     margin-bottom:4px;
    }
  }

    mat-card.mat-mdc-card{
      margin-bottom:4px;
      flex-direction: row;
      align-items: center;

    }

}

.boat-option{
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  background:white;
  border:1px solid #ddd !important;
  margin-bottom:4px !important;
  padding:16px !important;
  &:hover{
    background:whitesmoke;
   }
   &.selected{
     border-color:theme.$accent-default !important;
     background:rgba(theme.$accent-default,.12);
   }
  mat-icon{
    color:theme.$primary-default;
    font-size:22px;
    width:auto;
    height:auto;
    margin-right:4px;
  }

  .boat-option-info{
    display: flex;

  }
  .boat-option-item{
    width:auto;
    display:flex;
    font-size: 16px;
    flex-direction: row;
    margin-right:32px;
  }
}
.mat-mdc-radio-group .mdc-card{
  padding:0;
  box-shadow: none;;
  border:1px solid #ddd;
  margin-bottom:4px;

  &.selected{
    border-color:#f55000;
  }
}

@media (max-width:801px){
  .form-row{

    flex-direction: column;
    .form-label-heading{
      width:100%;
    }
    .form-element{
      width:100%;

      .mat-form-field{
        max-width:100%;
      }
    }
  }

  .form-row .form-element mat-card.mat-mdc-card{
    flex-direction: column;
  }
  .boat-option mat-icon, .mat-mdc-radio-group .mat-mdc-card mat-icon{
    font-size:18px;
  }
  .boat-option .boat-option-item, .mat-mdc-radio-group .mat-mdc-card .boat-option-item{
    font-size:15px;
  }
  .boat-option .boat-option-info, .mat-mdc-radio-group .mat-mdc-card .boat-option-info{
    align-self: flex-start;
  }

  .boat-option-info{
    flex-direction: column;
  }

 .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header{
    padding:8px;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label{
    padding:8px 0 0 0 !important;
  }

  .mat-horizontal-stepper-header-container{
    .mat-step-label-selected{
      font-size:14px;
    }

    .mat-step-icon{
      width:24px;
      height:24px;
    }
  }

  .mdc-evolution-chip-set{
    align-self: flex-end;
  }
}

.mdc-button--raised{
  box-shadow: 0 3px 0 0 #A0582E !important;
  &.mat-primary{
    box-shadow: 0 3px 0 0 #A0582E !important;
  }
}

.mat-mdc-unelevated-button.mat-unthemed{
  color:theme.$primary-default !important;
  background:#E9F6F8 !important;
}

.mdc-dialog__actions{
  padding:16px !important;
}

.swiper-horizontal{
  margin-bottom:16px !important;
}

.step-content{
  width:100%;
}

app-article-tile{
 mat-card{
    width:100% !important;
    height:100% !important;
  }
}

.bottom-sheet-close-button {
  position: absolute !important;
  top: 6px;
  right: 18px;
}

mat-icon {
  fa-icon {
    .svg-inline--fa {
      vertical-align: 0.125em;
    }
  }
}
