@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config:    mat.get-color-config($theme);
  $accent-palette:  map.get($color-config, 'accent');

  app-payment-method-select {
    mat-card {
      cursor: pointer;
    }
  }

  mat-card {
    @include mat.elevation(4);
    transition: box-shadow 200ms;

   
  }
}
