@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  ul {
    &.checkmark-bullets {
      li {
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: 1rem;

        &:before {
          position: absolute;

          content: '';
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background: url('/assets/images/checkmark.svg') no-repeat center center / contain;
          font-size: 1.5rem;

          top: 2px;
          left: 0;

          color: mat.get-theme-color($theme, primary, 500);
        }
      }
    }
  }
}
